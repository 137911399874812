import React from "react";

const Contact = () => {
  return (
    <div className="p-10 bg-blue-500 text-white">
      <h2 className="text-3xl font-bold text-center mb-4">Get in Touch</h2>
      <form className="max-w-lg mx-auto">
        <input
          type="text"
          placeholder="Name"
          className="w-full p-3 mb-4 rounded-lg"
        />
        <input
          type="email"
          placeholder="Email"
          className="w-full p-3 mb-4 rounded-lg"
        />
        <textarea
          placeholder="Your Message"
          className="w-full p-3 mb-4 rounded-lg"
        />
        <button className="w-full bg-white text-blue-500 py-3 rounded-lg font-bold">
          Submit
        </button>
      </form>
    </div>
  );
};

export default Contact;

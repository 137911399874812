import React, { useState } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import logo from "../logo1.png"; // Adjust the path if the file is nested deeper

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Show dropdown on mouse enter
  const handleMouseEnter = () => {
    setIsDropdownOpen(true);
  };

  // Hide dropdown on mouse leave
  const handleMouseLeave = () => {
    setIsDropdownOpen(false);
  };

  return (
    <header className="bg-white shadow-md fixed top-0 left-0 w-full z-50">
      <div className="container mx-auto flex justify-between items-center p-4">
        {/* Logo and Company Name */}
        <div className="flex items-center">
          <img src={logo} alt="Company Logo" className="h-12 w-12 mr-3" />
          <h1 className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-pink-600 via-yellow-400 to-blue-600 hover:scale-105 transition-transform duration-500 ease-in-out">
            <span className="text-shadow-md">THE</span>{" "}
            <span className="text-shadow-md">REAL</span>{" "}
            <span className="text-shadow-md">MINE</span>
          </h1>
        </div>

        {/* Navigation Links */}
        <nav className="hidden md:flex space-x-6">
          <Link to="#hero" className="text-gray-600 hover:text-blue-500">
            Home
          </Link>
          <Link to="#about" className="text-gray-600 hover:text-blue-500">
            About Us
          </Link>

          {/* Services Dropdown on Hover or Click */}
          <div
            className="relative"
            onMouseEnter={handleMouseEnter}  // Show dropdown when mouse enters
            onMouseLeave={handleMouseLeave}  // Hide dropdown when mouse leaves
          >
            <button
              className="flex items-center text-gray-600 hover:text-blue-500 font-semibold"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)} // Toggle dropdown visibility on click
            >
              Services
              <svg
                className="w-4 h-4 ml-2 text-gray-600"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            {/* Dropdown Menu */}
            {(isDropdownOpen || isOpen) && (
              <div className="absolute left-0 mt-2 w-48 bg-white shadow-lg rounded-lg z-10">
                <Link
                  to="/manufacturing"
                  className="block px-4 py-2 text-gray-600 hover:bg-blue-100"
                >
                  Manufacturing Equipment
                </Link>
                <Link
                  to="/solar"
                  className="block px-4 py-2 text-gray-600 hover:bg-blue-100"
                >
                  Solar & Electrical Equipment
                </Link>
                <Link
                  to="/construction"
                  className="block px-4 py-2 text-gray-600 hover:bg-blue-100"
                >
                  Construction Equipment
                </Link>
                <Link
                  to="/medical"
                  className="block px-4 py-2 text-gray-600 hover:bg-blue-100"
                >
                  Medical Equipment
                </Link>
              </div>
            )}
          </div>

          <Link to="#contact" className="text-gray-600 hover:text-blue-500">
            Contact
          </Link>
        </nav>

        {/* Mobile Menu (Hamburger) */}
        <button
          className="md:hidden flex items-center text-gray-600 focus:outline-none"
          onClick={toggleMenu}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </button>
      </div>

      {/* Mobile Menu Dropdown */}
      {isOpen && (
        <div className="md:hidden flex flex-col items-center py-4 bg-gray-100 shadow-lg">
          <Link to="#hero" className="text-gray-600 py-2 hover:text-blue-500">
            Home
          </Link>
          <Link to="#about" className="text-gray-600 py-2 hover:text-blue-500">
            About Us
          </Link>
          <Link to="/manufacturing" className="text-gray-600 py-2 hover:text-blue-500">
            Manufacturing Equipment
          </Link>
          <Link to="/solar" className="text-gray-600 py-2 hover:text-blue-500">
            Solar & Electrical Equipment
          </Link>
          <Link to="/construction" className="text-gray-600 py-2 hover:text-blue-500">
            Construction Equipment
          </Link>
          <Link to="/medical" className="text-gray-600 py-2 hover:text-blue-500">
            Medical Equipment
          </Link>
          <Link to="#contact" className="text-gray-600 py-2 hover:text-blue-500">
            Contact
          </Link>
        </div>
      )}
    </header>
  );
};

export default Header;

import React from "react";

const About = () => {
  return (
    <div className="p-10 bg-gray-100">
      <h2 className="text-3xl font-bold text-center mb-4">Who We Are</h2>
      <p className="text-lg text-center max-w-2xl mx-auto">
        We specialize in providing tailored consultancy for businesses looking
        to procure specialized equipment. From manufacturing to renewable
        energy, our expertise ensures you get the best solutions for your needs.
      </p>
    </div>
  );
};

export default About;

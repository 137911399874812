import React from "react";

const ConstructionPage = () => {
  return (
    <div className="bg-gray-50">
      <div className="max-w-screen-xl mx-auto p-10">
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">
          Construction Equipment Solutions
        </h1>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 mb-12">
          {/* First Image */}
          <div>
            <img
              src="/images/construction_1.jpg" // Update with actual image path
              alt="Construction Equipment"
              className="w-full h-64 object-cover rounded-lg shadow-md"
            />
          </div>

          {/* Page Content */}
          <div className="text-gray-700">
            <p className="mb-4 text-lg">
              Whether you're building a residential complex or a large
              infrastructure project, we supply top-of-the-line construction
              equipment. From excavators and cranes to mixers and bulldozers, we
              help you find the right machines for your needs, ensuring the job is
              done efficiently and on time.
            </p>
            <h2 className="text-2xl font-semibold mb-4">How We Help You</h2>
            <p className="mb-4">
              Our experts help you choose construction equipment that meets your
              project’s specific requirements. We source equipment from trusted
              suppliers, ensuring safety, reliability, and performance.
            </p>
            <h3 className="text-xl font-semibold mb-2">Supplier Analysis</h3>
            <p className="mb-4">
              We partner with reliable construction equipment suppliers to give
              you access to high-quality machines that fit your budget and project
              requirements.
            </p>
            <h3 className="text-xl font-semibold mb-2">Custom Solutions</h3>
            <p>
              From small projects to large-scale construction sites, we offer
              tailored equipment solutions to ensure that you have the tools you
              need for every phase of construction.
            </p>
          </div>
        </div>

        {/* Gallery Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-10">
          <img
            src="/images/construction_2.jpg" // Update with actual image path
            alt="Construction Equipment"
            className="w-full h-64 object-cover rounded-lg shadow-lg"
          />
          <img
            src="/images/construction_3.jpg" // Update with actual image path
            alt="Construction Equipment"
            className="w-full h-64 object-cover rounded-lg shadow-lg"
          />
          <img
            src="/images/construction_4.jpg" // Update with actual image path
            alt="Construction Equipment"
            className="w-full h-64 object-cover rounded-lg shadow-lg"
          />
        </div>

        {/* Call to Action */}
        <div className="text-center">
          <a
            href="#contact"
            className="inline-block bg-blue-600 text-white py-2 px-6 rounded-lg text-lg hover:bg-blue-700"
          >
            Start Your Construction Project Today
          </a>
        </div>
      </div>
    </div>
  );
};

export default ConstructionPage;

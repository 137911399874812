import React from "react";
import { Link } from "react-router-dom";

const services = [
  {
    title: "Manufacturing Equipment",
    description:
      "We provide state-of-the-art manufacturing equipment to streamline production processes, including CNC machines, robotics, and assembly line tools for enhanced efficiency.",
    image: "/images/manufacturing_1.jpg",
    link: "/manufacturing", // Link to the respective page
  },
  {
    title: "Solar & Electrical Equipment",
    description:
      "Empower your business with reliable and sustainable energy solutions. Our solar panels, inverters, and transformers ensure maximum energy efficiency and cost-effectiveness.",
    image: "/images/solar_1.jpg",
    link: "/solar", // Link to the respective page
  },
  {
    title: "Construction Equipment",
    description:
      "We provide high-quality construction equipment for various projects, from heavy machinery like excavators to essential tools for infrastructure development.",
    image: "/images/construction_1.jpg",
    link: "/construction", // Link to the respective page
  },
  {
    title: "Medical Equipment",
    description:
      "Ensure the best care for your patients with our premium medical equipment. We supply diagnostic tools, imaging machines, surgical instruments, and much more.",
    image: "/images/medical_1.jpg",
    link: "/medical", // Link to the respective page
  },
];

const Services = () => {
  return (
    <div className="p-10 bg-white">
      <h2 className="text-3xl font-bold text-center mb-6 text-gray-800">
        Industries We Serve
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {services.map((service, index) => (
          <Link
            key={index}
            to={service.link} // Link to the respective page
            className="bg-white p-6 rounded-lg shadow-lg transform transition duration-300 hover:scale-105 hover:shadow-xl"
          >
            {/* Image Section */}
            <div className="mb-4">
              <img
                src={service.image}
                alt={service.title}
                className="w-full h-48 object-cover rounded-lg"
              />
            </div>

            {/* Title Section */}
            <h3 className="text-xl font-bold text-gray-800">{service.title}</h3>

            {/* Description Section */}
            <p className="mt-4 text-gray-600">{service.description}</p>

            {/* Learn More Link */}
            <span className="mt-4 inline-block text-blue-600 hover:text-blue-800 font-semibold">
              Learn More →
            </span>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Services;

import React from "react";

const Hero = () => {
  return (
    <div className="bg-gradient-to-r from-blue-500 to-indigo-500 text-white p-10 text-center">
      <h1 className="text-4xl md:text-6xl font-bold">
        Simplifying Equipment Procurement Across Industries
      </h1>
      <p className="mt-4 text-lg md:text-2xl">
        Your trusted partner for reliable supplier recommendations.
      </p>
      <button className="mt-6 px-6 py-3 bg-white text-blue-500 rounded-lg font-bold text-lg hover:bg-gray-200">
        Get Expert Consultancy Now
      </button>
    </div>
  );
};

export default Hero;

import React from "react";

const ManufacturingPage = () => {
  return (
    <div className="bg-gray-50">
      <div className="max-w-screen-xl mx-auto p-10">
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">
          Manufacturing Equipment Solutions
        </h1>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 mb-12">
          {/* First Image */}
          <div>
            <img
              src="/images/manufacturing_1.jpg" // Update with actual image path
              alt="Manufacturing Equipment"
              className="w-full h-64 object-cover rounded-lg shadow-md"
            />
          </div>

          {/* Page Content */}
          <div className="text-gray-700">
            <p className="mb-4 text-lg">
              We specialize in helping businesses find the most advanced and
              reliable manufacturing equipment. Whether you’re looking for CNC
              machines, robotics, or assembly line tools, we assess and analyze
              various suppliers to deliver the right equipment that enhances
              productivity and reduces downtime.
            </p>
            <h2 className="text-2xl font-semibold mb-4">How We Help You</h2>
            <p className="mb-4">
              Our approach is simple but effective. We work closely with you to
              understand your specific needs and challenges. By sourcing equipment
              from trusted suppliers, we ensure that your production processes run
              smoothly. We take care of everything, from initial consultation to
              post-purchase support.
            </p>
            <h3 className="text-xl font-semibold mb-2">Supplier Analysis</h3>
            <p className="mb-4">
              We thoroughly analyze a wide range of equipment suppliers, ensuring
              that you get the best quality at competitive prices. Our expertise
              helps you avoid costly mistakes and delays by choosing the right
              supplier for your specific requirements.
            </p>
            <h3 className="text-xl font-semibold mb-2">Custom Solutions</h3>
            <p>
              Every business is different, and we recognize that. We provide
              tailored solutions that match your unique operational needs and
              budget. Whether you need specialized machinery or standard
              equipment, we’ve got you covered.
            </p>
          </div>
        </div>

        {/* Gallery Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-10">
          <img
            src="/images/manufacturing_2.jpg" // Update with actual image path
            alt="Manufacturing Equipment"
            className="w-full h-64 object-cover rounded-lg shadow-lg"
          />
          <img
            src="/images/manufacturing_3.jpg" // Update with actual image path
            alt="Manufacturing Equipment"
            className="w-full h-64 object-cover rounded-lg shadow-lg"
          />
          <img
            src="/images/manufacturing_4.jpg" // Update with actual image path
            alt="Manufacturing Equipment"
            className="w-full h-64 object-cover rounded-lg shadow-lg"
          />
        </div>

        {/* Call to Action */}
        <div className="text-center">
          <a
            href="#contact"
            className="inline-block bg-blue-600 text-white py-2 px-6 rounded-lg text-lg hover:bg-blue-700"
          >
            Get Started with Your Equipment Procurement
          </a>
        </div>
      </div>
    </div>
  );
};

export default ManufacturingPage;

import React from "react";

const MedicalPage = () => {
  return (
    <div className="bg-gray-50">
      <div className="max-w-screen-xl mx-auto p-10">
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">
          Medical Equipment Solutions
        </h1>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 mb-12">
          {/* First Image */}
          <div>
            <img
              src="/images/medical_1.jpg" // Update with actual image path
              alt="Medical Equipment"
              className="w-full h-64 object-cover rounded-lg shadow-md"
            />
          </div>

          {/* Page Content */}
          <div className="text-gray-700">
            <p className="mb-4 text-lg">
              We provide healthcare providers with state-of-the-art medical
              equipment to enhance patient care. From diagnostic tools and imaging
              machines to surgical instruments, we help you source reliable and
              precise equipment tailored to your medical facility's needs.
            </p>
            <h2 className="text-2xl font-semibold mb-4">How We Help You</h2>
            <p className="mb-4">
              Our team works with medical professionals to identify equipment
              needs and match them with the best suppliers. Whether you need
              hospital-grade machines or specific diagnostic equipment, we ensure
              you get what you need quickly and efficiently.
            </p>
            <h3 className="text-xl font-semibold mb-2">Supplier Analysis</h3>
            <p className="mb-4">
              We carefully analyze various medical equipment suppliers to ensure
              that you get top-quality products that meet health and safety
              standards. Our partnerships help you access the best medical
              equipment at competitive prices.
            </p>
            <h3 className="text-xl font-semibold mb-2">Custom Solutions</h3>
            <p>
              We understand the diverse needs of healthcare providers. Our
              customized equipment solutions ensure that your hospital, clinic, or
              practice has the tools needed to deliver exceptional care.
            </p>
          </div>
        </div>

        {/* Gallery Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-10">
          <img
            src="/images/medical_2.jpg" // Update with actual image path
            alt="Medical Equipment"
            className="w-full h-64 object-cover rounded-lg shadow-lg"
          />
          <img
            src="/images/medical_3.jpg" // Update with actual image path
            alt="Medical Equipment"
            className="w-full h-64 object-cover rounded-lg shadow-lg"
          />
          <img
            src="/images/medical_4.jpg" // Update with actual image path
            alt="Medical Equipment"
            className="w-full h-64 object-cover rounded-lg shadow-lg"
          />
        </div>

        {/* Call to Action */}
        <div className="text-center">
          <a
            href="#contact"
            className="inline-block bg-blue-600 text-white py-2 px-6 rounded-lg text-lg hover:bg-blue-700"
          >
            Get Started with Your Medical Equipment Procurement
          </a>
        </div>
      </div>
    </div>
  );
};

export default MedicalPage;

import React from "react";

const SolarPage = () => {
  return (
    <div className="bg-gray-50">
      <div className="max-w-screen-xl mx-auto p-10">
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">
          Solar & Electrical Equipment Solutions
        </h1>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 mb-12">
          {/* First Image */}
          <div>
            <img
              src="/images/solar_1.jpg" // Update with actual image path
              alt="Solar Equipment"
              className="w-full h-64 object-cover rounded-lg shadow-md"
            />
          </div>

          {/* Page Content */}
          <div className="text-gray-700">
            <p className="mb-4 text-lg">
              We provide cutting-edge solar and electrical solutions that power
              your business sustainably. From solar panels and inverters to
              transformers, we source reliable and energy-efficient equipment to
              help you reduce energy costs and make the transition to renewable
              energy smoother.
            </p>
            <h2 className="text-2xl font-semibold mb-4">How We Help You</h2>
            <p className="mb-4">
              Our experts guide you through the process of selecting the right
              solar and electrical equipment. We understand the unique energy
              needs of each business and ensure that you receive the most
              cost-effective and efficient solutions.
            </p>
            <h3 className="text-xl font-semibold mb-2">Supplier Analysis</h3>
            <p className="mb-4">
              We evaluate a variety of solar and electrical equipment suppliers to
              ensure you get the highest quality products at the best prices.
              Our analysis helps you make informed decisions that align with your
              energy goals and budget.
            </p>
            <h3 className="text-xl font-semibold mb-2">Custom Solutions</h3>
            <p>
              Each business has different energy needs. We offer tailored
              solutions to meet your specific requirements, whether it's for
              residential, commercial, or industrial applications.
            </p>
          </div>
        </div>

        {/* Gallery Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-10">
          <img
            src="/images/solar_2.jpg" // Update with actual image path
            alt="Solar Equipment"
            className="w-full h-64 object-cover rounded-lg shadow-lg"
          />
          <img
            src="/images/solar_3.jpg" // Update with actual image path
            alt="Solar Equipment"
            className="w-full h-64 object-cover rounded-lg shadow-lg"
          />
          <img
            src="/images/solar_4.jpg" // Update with actual image path
            alt="Solar Equipment"
            className="w-full h-64 object-cover rounded-lg shadow-lg"
          />
        </div>

        {/* Call to Action */}
        <div className="text-center">
          <a
            href="#contact"
            className="inline-block bg-blue-600 text-white py-2 px-6 rounded-lg text-lg hover:bg-blue-700"
          >
            Get Started with Solar Solutions
          </a>
        </div>
      </div>
    </div>
  );
};

export default SolarPage;

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Importing Components
import Header from "./components/Header";
import Hero from "./components/Hero";
import About from "./components/About";
import Services from "./components/Services";
import Contact from "./components/Contact";
import Footer from './components/Footer';

// Industry Pages
import ManufacturingPage from './pages/ManufacturingPage';
import SolarPage from './pages/SolarPage';
import ConstructionPage from './pages/ConstructionPage';
import MedicalPage from './pages/MedicalPage';

function App() {
  return (
    <Router>
      <div>
        <Header />
        <main className="pt-20"> {/* Offset for fixed header */}
          <Routes>
            {/* Home Page */}
            <Route path="/" element={
              <>
                <section id="hero"><Hero /></section>
                <section id="about"><About /></section>
                <section id="services"><Services /></section>
                <section id="contact"><Contact /></section>
              </>
            } />

            {/* Industry Pages */}
            <Route path="/manufacturing" element={<ManufacturingPage />} />
            <Route path="/solar" element={<SolarPage />} />
            <Route path="/construction" element={<ConstructionPage />} />
            <Route path="/medical" element={<MedicalPage />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
